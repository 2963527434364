import React from 'react'
import TitleComponent from './title-component'
import ProjectData from '../content/projects.yml'

const Projects = ()=>{
    let data
    data=ProjectData.projects
    data=data.slice().reverse()
    let pdata = data.map((d, i) =>{
        return(
            <div className="project-data col-lg-3 col-md-4 col-12" id="myprojects" key={i}>
                <h2 className="title">{d.name}</h2>
                    <p className="link">
                        {d.github ? 
                            <a href={"https://"+d.github} target="_blank" rel="noreferrer" style={{wordWrap: "break-word", marginRight: "10px"}}>
                                <i className="fa fa-github" style={{fontSize: "1.8rem"}}></i>
                            </a> 
                            :
                            null
                        }
                        {d.link ? 
                            <a href={"https://"+d.link} target="_blank" rel="noreferrer" style={{wordWrap: "break-word"}}>
                                <i className="fa fa-globe" style={{fontSize: "1.8rem"}}></i>
                            </a>
                            :
                            null
                        }
                    </p>
                <p className="desc">{d.desc}</p>
            </div>
        )
    })

    return(
        <div className="Projects">
            <div className="container">
                <div className="content">
                    <TitleComponent text="My Projects"/>
                    <div className="row mt-5">
                        {pdata}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects