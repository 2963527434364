import React from 'react';

import Layout from '../components/mylayout'
import Projects from '../components/projects'

import SEO from '../components/seo'

const About =({location})=>{
    return(
        <Layout location={location}>
            <SEO 
                title="About Me"
                description="CSE undergraduate student, web developer and quantum programmer"
                links={[
                    {"rel": "stylesheet",
                     "type": "text/css",
                     "href": "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                    }
                ]}
            />
            <div className="AboutMe">
                <div className="container">
                    <div className="content">
                        <h1 className="heading">About Me</h1>
                        <div className="desc">
                            <p className="paragraph">I’m Roshan, a Software Engineer working in Rails + React stack.</p>

                            <p className="paragraph">This is my place on the internet to write about my ideas, 
                            projects and anything that I want to share with the world.</p>

                            <p className="paragraph">I created my first website when I was in highschool and 
                            I've been making them ever since. I love to learn complex tech concepts, break it down and 
                            share what I learn.</p>

                            <p className="paragraph">If you’re interested in tech, feel free to follow my blog!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Interests">
                <div className="container">
                    <div className="content">
                        <div className="Interests__card">
                            <h2>Web Development</h2>
                            <p>I love to create web applications! With experience in building full-stack solutions using React and Ruby on Rails, I focus on creating scalable, high-quality software while keeping the development process agile and efficient.
                            </p>
                        </div>
                        <div className="Interests__card">
                            <h2>AI & Quantum Computing</h2>
                            <p>I’m actively learning about the fields of AI and Quantum Computing. I believe that, together, these technologies could help us unlock some of the universe's biggest mysteries. My goal is to make a real impact in this area.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Projects/>
        </Layout>
    )
}

export default About;